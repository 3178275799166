const axios = require("axios");

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = async () => {
  if (typeof window === "undefined") {
    return;
  }
  const hash = window.location.hash.slice(1);
  if (hash === "" || window.location.pathname.includes('confirm-reset-password')) {
    return;
  }
  const localFormValues = localStorage.getItem(hash);
  if (!localFormValues) {
    const response = await axios.get(
      process.env.GATSBY_API_URL + "/get-registration?request_id=" + hash
    );
    if (response.data) {
      localStorage.setItem(hash, JSON.stringify(response.data));
    }
  }
};
